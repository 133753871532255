.info-container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #999;
    margin-top: 20px;
    padding: 20px 0 0 0;
    overflow: auto;
}

.info-header {
    display: grid;
    grid-template-columns: 1.3fr repeat(5, 1fr);
    gap: 8px;
    /* padding: 0 13.125px; */
}
.header-item {
    font-family: "Inter Bold";
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 8px; */
    padding: 8px 8px 8px 0;
    border-right: 1px solid #999;
    color: #1e1e1e;
    text-align: center;
    min-width: 152px;
}
.header-item-action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}
.header-item-isin {
    font-weight: 300;
    font-size: 14px;
}

.header-item-delete {
    width: 24px;
    cursor: pointer;
}


.panel-container {
    margin-top: 24px;
}
.panel-header {
    padding: 8px 24px;
    background: #056dae;
    color: #fff;
    cursor: pointer;
    position: relative;
    font-family: "Inter Bold";
}
.panel-header-icon {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: #fff;
    left: 8px;
    top: 50%;
    transform: rotate(180deg);
    transition: all 0.3s ease;
}
.panel-header-icon-close {
    transform: translateY(-50%) rotate(90deg);
}

.panel-collapse {
    transform-origin: top;
}

.collapse-row {
    display: grid;
    grid-template-columns: 1.3fr repeat(5, 1fr);
    gap: 8px;
    /* padding: 0 13.125px; */
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    color: #1e1e1e;
}
.collapse-row-header {
    border-right: 1px solid #ccc;
    padding: 8px 8px 8px 0;
    display: flex;
    /* align-items: center; */
    min-width: 160px;
}
.collapse-row-item {
    border-right: 1px solid #ccc;
    padding: 8px 0;
    min-width: 160px;
}
.collapse-row-header-icon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.style-box-container {
    display: flex;
    align-items: center;
    justify-content:  space-between;
    padding-right: 8px;
}
.box-container {
    display: grid;
    grid-template-columns: repeat(3, 12px);
    grid-template-rows: repeat(3, 12px);
    /* gap: 1px; */
}
.box-container-item {
    border-top: 1px solid #000;
    border-right: 1px solid #000;
}