.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 24px;
}
.item {
    display: flex;
    flex-direction: column;
}
.item-title {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    height: 21px;
    /* min-height: 24px; */
}
.item-holdings {
    grid-column: 1 / -1;

}
.item-holdings-equity {
    grid-column-start: span 2;
}

.style-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;
    padding-right: 40px;
}
.style-box-title {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    color: #333333;
}
.style-box-group {
    margin: 0 45px 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-rows: 1fr 1fr 1fr; */
    gap: 4px;
    position: relative;
}
.style-box-group-item {
    height: 0;
    padding-bottom: 100%;
    background: #EBECEC;
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.group-right-info {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 45px;
    display: flex;
}
.group-right-info-left {
    display: grid;
    gap: 4px;
    margin-right: 4px;
}
.group-right-info-left >span {
    writing-mode: vertical-rl;
    font-size: 12px;
}
.group-right-info-right >span{
    writing-mode: vertical-rl;
    line-height: 1.55;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter Semibold";
    color: #056dae;
}
.group-bottom-info {
    position: absolute;
    margin-top: 5px;
    top: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px;
    font-size: 12px;
}
.style-box-bottom-tips {
    line-height: 1.55;
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter Semibold";
    color: #056dae;
}

.asset-main {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
}
.asset-main-item {
    display: flex;
    align-items: center;
    padding: 7px 18px;
    font-size: 14px;
    line-height: 1.5;
    gap: 6px;
    background: #F3F2F2;
    border-radius: 5px;
    justify-content: space-between;
    margin-bottom: 8px;
}
.asset-main-item:last-child {
    margin-bottom: 0;
}

.asset-main-item-tips {
    color: #056dae;
    font-weight: 600;
    font-family: "Inter Semibold";
    background: #fff;
}
.asset-item-group {
    flex: 1;
    display: flex;
    align-items: center;
    /* gap: 6px; */
}
.asset-item-group >img {
    margin-right: 6px;
}
.asset-item-group-data {
    flex-grow: 0.4;
}
.asset-item-group-holdings {
    width: 25%;
    display: flex;
    flex-direction: row-reverse;
}

.holdings-action {
    display: flex;
    padding-left: 18px;
    margin-top: 10px;
}
.holdings-action-item {
    display: flex;
    align-items: center;
    margin-right: 25px;
}
.holdings-action-item >img {
    margin-right: 10px;
}

@media (max-width: 1280px) {
    .container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 767px) {
    .container {
        grid-template-columns: 1fr;
        /* overflow: hidden; */
    }
    .item-holdings-equity {
        grid-column-start: span 1;
    }
    .asset-main-item-holdings {
        font-size: 12px;
    }
    .asset-main-item-holdings-sector {
        width: 25%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
    }
    .holdings-action {
        /* display: grid;
        grid-template-columns: 1fr;
        gap: 6px; */
        padding-left: 0;
        font-size: 12px;
        justify-content:  space-between;
    }
    .holdings-action-item {
        margin-right: 6px;
    }
    .holdings-action-item:last-child {
        margin-right: 0;
    }
    .holdings-action-item >img {
        margin-right: 3px;
    }

}