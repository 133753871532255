.container {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    width: 30%;
    flex-shrink: 0;
    margin-right: 24px;
}
.container-hide {
    display: none;
}

.title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
}

.title-benchmark {
    margin-bottom: 24px;
}

.header {
    margin: 12px 0;
    font-weight: 500;
    display: flex;
    flex-direction: row-reverse;
}
.header > span {
    width: 30%;
    flex-shrink: 0;
    color: #056dae;
}

.info {
    display: flex;
    flex-direction: column;
    position: relative;
}
.info-item {
    display: grid;
    grid-template-columns: 70% 30%;
    background: #F3F2F2;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 0;
    color: #000000;
    position: relative;
}
.info-input-group {
    flex-shrink: 0;
    margin: 0 16px;
    display: flex;
    align-items: center;
    position: relative;
}
.info-input-group > span {
    width: 24px;
}
.info-input-group-loading {
    position: absolute;
    right: 2px;
    width: 16px;
    height: 16px;
    border: 3px solid #666;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    display: none;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.info-input {
    outline: none;
    height: 24px;
    border: 1px solid #A9A9A9;
    /* flex: 1; */
    width: 100%;
    border-radius: 4px;
    font-size: 12px;
    padding-right: 20px;
    min-width: 40px;
}
.info-input:focus,.info-input:active {
    border: 1px solid #004988;
}

.info-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
.info-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
  /* Firefox */
.info-input[type='number'] {
    -moz-appearance: textfield;
}

.info-item-action {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.info-item-action > img {
    margin: 0 16px;
    cursor: pointer;
}
.info-item-action > img:hover {
    opacity: 0.8;
}

.info-item-tips {
    font-weight: 700;
    margin-left: 40px;
}
.info-item-weight {
    font-weight: 700;
}
.info-item-error {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 8px);
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #8b0808;
}


.select-list {
    position: absolute;
    top: calc(100% - 4px);
    left: 35px;
    right: 35px;
    background: #fff;
    z-index: 998;
    max-height: 200px;
    padding: 15px 0;
    border: 1px solid #999999;
    border-top: 2px solid #004988;
    box-shadow: 0px 4px 8px 1px rgba(59,59,59,0.35);
    overflow: auto;
    outline: none;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
}
.select-list-title {
    padding-left: 20px;
    color: #056dae;
    font-weight: 700;
    margin-bottom: 8px;;
}
.select-list-title-empty {
    margin: 0;
    color: #444;
}
.select-list-item {
    padding: 5px 6px 5px 20px;
    color: #444;
    cursor: pointer;
}
.select-list-item:hover {
    background: #056dae;
    color: #FFFFFF;
    font-weight: 600;
}



.footer {
    border-top: 1px solid #666666;
    margin-top: 60px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1280px) {
    .container {
        width: auto;
        width: 310px;
        /* min-height: 250px; */
    }
}

@media (max-width: 767px) {
    .container {
        margin-right: 0;
        width: 100%
    }
    .title-benchmark {
        margin-bottom: 12px;
    }
    .footer {
        padding-top: 12px;
        margin-bottom: 24px;
    }
}