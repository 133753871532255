.container {
    width: 1050px;
    margin: -18px auto 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

@media (max-width: 1280px) {
    .container {
        width: auto;
        padding: 0 16px;
    }
    /* .tabs {
        flex-wrap: wrap;
    } */
}