.container {
    margin-top: 12px;
    overflow: auto;
    position: relative;
    /* overflow: hidden; */
}
.table {
    width: 100%;
    /* overflow: auto; */
    /* table-layout:fixed; */
    border-spacing: 0;
}
.th {
    word-break:break-all;
    /* padding: 0 30px; */
    padding-right: 30px;
    color: #fff;
    height: 65px;
    border: none;
    background: #056dae;
    font-size: 14px;
    font-weight: 400;
}
.th:first-child {
    padding-left: 30px;
}
.header {
    position: relative;
}
.header:before { 
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    top: calc(100% + 12px);
    background: #fff;
}


.tr {
    background: #fff;
    font-size: 14px;
    display: flex;
    /* margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1); */
    padding: 10px 0;
    position: relative;
    color: rgba(40, 48, 63, 0.8);
    border-radius: 10px;
    width: 1050px;
    overflow: auto;
    position: relative;
    display: table-row;
}
.tr::-webkit-scrollbar {
    position: absolute;
    width: 9px;
    height: 9px;
    /*background-color: #ddd;*/
}

.tr::-webkit-scrollbar-thumb {
    background: rgba(40, 48, 63, 0.1);
    border-radius: 10px;
}

.tr::-webkit-scrollbar-track {
    background: rgba(40, 48, 63, 0.04);
    border-radius: 10px;
}

.tr:nth-child(2n) {
    background: #F3F3F3;
}


.td {
    text-align: center; 
    vertical-align: middle;
    padding: 8px 30px 8px 0;
}
.td-info {
    display: none;
}

.td:first-child {
    padding-left: 30px;
}

@media (max-width: 1280px) { 
    
    .tr {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding: 8px 20px 20px 20px;
        box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
        width: auto;

        margin-top: 16px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
        border: 1px solid #D9D9D9;
        position: relative;
    }
    .tr:nth-child(2n) {
        background: #fff;
    }
    .td {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: auto;
    }
    .td:first-child {
        padding-left: 0;
    }
    .td-info {
        display: flex;
        align-items: center;
        min-width: auto;
        margin-right: 35px;
        min-height: 32px;
    }
    .td-cell {
        text-align: right;
    }
    
}
@media (max-width: 767px) {
    .td {
        justify-content: space-between;
        padding: 8px 0;
    }
}