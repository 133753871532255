.select {
    width: 100%;
    height: 36px;
    outline: none;
    background: #fff;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content:  space-between;
    cursor: pointer;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    /* border-bottom: 1px solid #999999; */
    /* justify-content: center; */
}
.select:hover {
    border: 1px solid #056dae;
}
.select-value {
    font-size: 14px;
    line-height: 16px;
    color: #444;
    margin-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis; 
    -o-text-overflow: ellipsis;
    white-space:nowrap;
}
.select-img {
    width: 15px;
    margin-right: 10px;
    transform: rotate(90deg);
}

.list {
    background: #fff;
    position: absolute;
    top: 100%;
    z-index: 998;
    left: 0;
    right: 0;
    max-height: 200px;
    border: 1px solid #999999;
    border-top: 2px solid #05589d;
    box-shadow: 0px 4px 8px 1px rgba(59,59,59,0.35);
    overflow: auto;
}

.list-item {
    padding: 5px 6px;
    line-height: 15px;
    font-size: 14px;
    color: #444;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content:  space-between;
}

.list-item:hover {
    /* background: #3875bd; */
    background: #00bdf2;
    color: #fff;
}

.list-item-img {
    width: 15px;
    margin-right: 3px;;
}