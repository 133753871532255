.container {
    width: calc(50% - 6px);
    flex-shrink: 0;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px;
    overflow: hidden;
}

.title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
}
.info {
    display: flex;
}

.info-chart {
    width: 52%;
    flex-shrink: 0;
}
.info-list {
    margin-left: 12px;
    width: 48%;
    display: flex;
    flex-direction: column;
}

.info-list-item {
    background: #F3F2F2;
    padding: 8px 10px;
    margin-bottom: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.info-list-item-header {
    background: #fff;
    color: #056dae;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.info-list-item-left {
    display: flex;
    align-items: center;
}
.info-list-item-left-box {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 10px;
}
.list-chart {
    /* flex: 1; */
    width: 45%;
    flex-shrink: 0;
    margin-top: 42px;
}

.list-info {
    width: 55%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.list-info-item {
    background: #F3F2F2;
    padding: 8px 10px;
    margin-bottom: 8px;
    font-size: 12px;
    display: grid;
    grid-template-columns: 4fr 2fr 2fr;
}
.list-info-item-header {
    background: #fff;
    color: #056dae;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.tooltip {
    display: flex;
    flex-direction: column;
}
.tooltip-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    font-family: "Inter";
}

.tooltip-info {
    width: 100px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #9e9e9e;
}
.tooltip-info-item {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.tooltip-info-item:last-child { 
    margin-bottom: 0;
}
@media (max-width: 1280px) {
    .container {
        width: auto;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .info-chart {
        width: 45%;
        flex-shrink: 0;
    }
    .info-list {
        flex: 1;
    }

    .list-chart {
        width: 40%;
    }
}