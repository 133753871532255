.container {
    margin-top: 24px;
    overflow: auto;
    /* overflow: hidden; */
}
.container > table {
    width: 100%;
    /* overflow: auto; */
    /* table-layout:fixed; */
    border-spacing: 0;
}

thead {
    /* display: none; */
}

.th {
    word-break:break-all;
    /* padding: 0 30px; */
    padding-right: 30px;
    color: #fff;
    height: 65px;
    border: none;
    background: #056dae;
    font-size: 14px;
    font-weight: 400;
    position: relative;
}
.header {
    position: relative;
}
.header:before { 
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    top: calc(100% + 12px);
    background: #fff;
}

.table-sort {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0 auto 0 0;
    border-top: 1px solid #000000;
}
.table-sort-down {
    position: absolute;
    top: -12px;
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: #000000 transparent transparent transparent;
}
.table-sort-up {
    position: absolute;
    top: -30px;
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: transparent transparent #000000 transparent;
}



.row {
    margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    position: relative;
}
.tr:first-child {
    margin-top: 0;
}
.row::before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: 60px;
    height: 1px;
    background: rgba(40, 48, 63, 0.3);
    z-index: 1;
}


.tr {
    background: #fff;
    font-size: 14px;
    display: flex;
    /* margin-top: 16px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1); */
    padding: 10px 0;
    position: relative;
    color: rgba(40, 48, 63, 0.8);
    border-radius: 10px;
    width: 1050px;
    overflow: auto;
    position: relative;

    display: table-row;
}
.tr::-webkit-scrollbar {
    position: absolute;
    width: 9px;
    height: 9px;
    /*background-color: #ddd;*/
}

.tr::-webkit-scrollbar-thumb {
    background: rgba(40, 48, 63, 0.1);
    border-radius: 10px;
}

.tr::-webkit-scrollbar-track {
    background: rgba(40, 48, 63, 0.04);
    border-radius: 10px;
}

.tr:nth-child(2n) {
    background: #F3F3F3;
}
.td {
    flex-shrink: 0;
    padding: 6px;
    /* display: flex;
    flex-direction: column; */
    margin-right: 6px;
    width: 160px;
}
.td-info {
    height: 35px;
    margin-bottom: 30px;
    display: none;
}

.td-selection {
    width: auto;
}
.td-name {
    width: 120px;
}
.td-document {
    width: auto;
    margin-right: 30px;
}
.td-document:last-child {
    margin-right: 12px;
}
.td-cell {
    color: rgba(40, 48, 63, 0.6);
}

.td-cell-active {
    color: rgba(40, 48, 63, 0.6);
}

.pagination {
    margin-top: 12px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    min-height: 32px;
}
.pagination-top {
    display: flex;
    padding: 0 12px;
    height: 25px;
    align-items: center;
    /* border-radius: 20px; */
    /* border: 1px solid rgba(69, 90, 100, 0.7) */
}
.pagination-number {
    /* width: 32px;
    height: 32px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0;
    margin-left: 8px;;
    cursor: pointer;
    color: rgba(69, 90, 100, 0.7);
    padding: 2px 5px;
    width: 22px;
    height: 28px;
}
.pagination-number:hover {
    color: #056dae;
}
.pagination-number-active {
    background: #056dae;
    color: #fff;
}
.pagination-number-active:hover {
    color: #fff;
}
.pagination-number:first-child {
    margin-left: 0;
}
.pagination-top-default {
    padding: 2px 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    color: rgba(69, 90, 100, 0.7)
}
.pagination-top-default:hover {
    color:#056dae;
}

.pagination-bottom {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(69, 90, 100, 0.7);
}
.pagination-select-group {
    margin-left: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.checkbox {
    margin: 0 6px;;
    width: 18px;
    height: 18px;
    /* margin-left: 10px; */
    cursor: pointer;
    border-radius: 2px;
    background: #fff;
    -webkit-appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.7);
}
.checkbox:checked {
    background-color: #056dae;
    border: 1px solid transparent;
    position: relative;
}
.checkbox:checked::before {
    content: " ";
    box-shadow: none;
    opacity: 1;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    border: 2.5px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    top: 3px;
    left: 2px;
}

@media (max-width: 1280px) {
    thead {
        display: none;
    }
    .row::before {
        display: none;
    }
    .tr {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding: 8px 20px 20px 20px;
        box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
        width: auto;

        margin-top: 16px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
        border: 1px solid #D9D9D9;
        position: relative;
    }
    .tr-active {
        color: rgba(255, 255, 255, 0.8);
        background: #056dae;
    }

    .tr::before {
        display: none;
    }
    
    .tr:nth-child(2n) {
        background: #fff;
    }
    .tr-active:nth-child(2n) {
        background: #056dae;
    }
    .td {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: auto;
    }
    .td-info {
        display: flex;
        align-items: center;
        margin-right: 64px;
        min-width: 250px;
        height: 0;
        margin-bottom: 0;
    }
    .td-cell-active  {
        color: rgba(255, 255, 255, 0.8);
    }
    .checkbox {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .td {
        justify-content: space-between;
    }
    .td-info {
        /* border: 1px solid red; */
        display: flex;
        min-width: auto;
        margin-right: 8px;
        min-height: 32px;
    }
    .td-cell {
        flex: 1;
        display: flex;
        flex-direction: row-reverse;
    }
    .td-cell:first-child {
        flex: 0;
    }
    .td-document {
        margin-right: 0;
    }
    .td-document:last-child {
        margin-right: 0;
    }

    .pagination {
        display: flex;
        flex-direction: column;
    }
}