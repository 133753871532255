.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border: 1px solid transparent;
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
    position: relative;
    padding: 0 8px;
}
.container-disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.label {
    font-size: 12px;
    color: #444444;
}

.select-img {
    width: 15px;
    margin-right: 10px;
    transform: rotate(90deg);
}

.list {
    background: #fff;
    position: absolute;
    z-index: 998;
    top: calc(100% + 4px);
    left: calc(-100% - 12px);
    right: 0;
    max-height: 200px;
    border: 1px solid #999999;
    border-top: 2px solid #05589d;
    box-shadow: 0px 4px 8px 1px rgba(59,59,59,0.35);
    overflow: auto;
}

.list-item {
    padding: 5px 6px;
    line-height: 15px;
    font-size: 14px;
    color: #444;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content:  space-between;
}

.list-item:hover {
    background: #056dae;
    color: #fff;
}

.list-item-img {
    width: 15px;
    margin-right: 3px;;
} 