.container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    min-height: 500px;
}

.action {
    display: flex;
    align-items: center;
}
.action-button {
    background: #deae1a;
    border-radius: 6px;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    font-weight: 700;
    margin-right: 12px;
    cursor: pointer;
    width: 75px;
}
.action-button:hover {
    opacity: 0.8;
}
.action-button-active {
    background: #056dae;
    border-radius: 6px;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    font-weight: 700;
    margin-right: 12px;
    width: 75px;
}

.chart {
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: 12px;
}

@media (max-width: 1280px) {

}

@media (max-width: 767px) {
    .action {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 4px;
    }
    .action-button {
        margin-right: 0;
    }
    .action-button-active {
        margin-right: 0;
    }
    .action-button-last {
        width: auto;
        grid-column-start: span 2;
        margin-right: 8px;
    }
}