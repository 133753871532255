.tooltip {
    display: flex;
    flex-direction: column;
}
.tooltip-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    font-family: "Inter";
}

.tooltip-info {
    min-width: 100px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #9e9e9e;
}
.tooltip-info-item {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.tooltip-info-item:last-child { 
    margin-bottom: 0;
}