.container {
    width: 1050px;
    margin: 40px auto 0 auto;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-container {
    width: 50%;
    position: relative;
    border-radius: 65px;
    margin-right: 24px;
}
.input-container::after {
    content: "";
    position: absolute;
    height: 17px;
    width: 17px;
    left: 12px;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 1;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAbNJREFUOE+1001Kw0AUAOCZpI2ILQoluFBLRVyIIv6fQMRrZFXKTEt7gpAbhCTTklVPoRtBwY0oKC5cia4Ef1pK1UWxSd+MjBhRG9qNZvmSfO9n3mD0Bw/+AwPFIr7vJ4MgmFYUZRxjrAohOkKIBqW0FZe0D7FtO6tp2qIQ4gEh1OKcA8Y4hTGeEkK8NJvNS8uy+HfsByKBRCIxH4bheaVSef6dtVarLQHAGKX0NBaRLQDAdrfbPYkDop9c193QNK2dz+dvo9hXJa7rzsoZEEIuBw27Xq9PdDqdVULIUR/CGFuRMyCE3A07McbYbqPROLQsK5DfflVSrVbXAeChWCzeD0Mcx9lJp9PHhmG8/UAYYwtCCKCUXg9CTNPUdF3fppTu97XjeV4GY7z8vdc4zHGcOTk7SulFHyIDnuetqaoaFAqFqzjgM9EOAOyVSqXXWMQ0TUXX9U3ZViqVujEM42NXZAuZTGZGVdUtzrkKAAflcvkpFomCvu/P9Xq9LEJoBAC4oigJhNAj5/yGcz6aTCa3wjA8i6CBF1BWkMvllOgUoiS2bU+22+1mtP7/d4uH7cnv9++5/MASOJ/zQgAAAABJRU5ErkJggg==);
    background-size: cover;
}

.input {
    width: calc(100% - 75px);
    height: 100%;
    height: 44px;
    border: 1px solid transparent;
    border-radius: 65px;
    outline: 0 none;
    padding: 0 37px 0 38px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
}
.input:hover, .input:active, .input:focus{
    border: 1px solid #056dae;
}
.input-loading {
    position: absolute;
    top: 14px;
    right: 16px;
    width: 16px;
    height: 16px;
    border: 3px solid #666;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    display: none;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.action-compare {
    height: 48px;
    display: flex;
    align-items: center;
    background: #056dae;
    cursor: pointer;
    border-radius: 24px;
    padding: 0 50px 0 24px;
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    margin-right: 24px;
}
.action-compare:hover {
    background: #05589d;
}
.action-compare > img {
    margin-right: 24px;
}

.select-list {
    position: absolute;
    top: calc(100% + 4px);
    left: 6px;
    right: 6px;
    background: #fff;
    z-index: 998;
    max-height: 120px;
    /* padding: 15px 0; */
    border: 1px solid #999999;
    border-top: 2px solid #004988;
    box-shadow: 0px 4px 8px 1px rgba(59,59,59,0.35);
    overflow: auto;
    outline: none;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
}
.select-list-title {
    color: #056dae;
    font-weight: 700;
    margin-bottom: 8px;
    text-align: center;
}
.select-list-title-empty {
    margin: 0;
    color: #444;
}
.select-list-item {
    /* padding: 5px 6px 5px 20px; */
    padding: 5px 6px;
    line-height: 15px;
    color: #444;
    cursor: pointer;
}
.select-list-item:hover {
    background: #056dae;
    color: #FFFFFF;
    font-weight: 600;
    font-family: "Inter Semibold";
}

.select-list-loader {
    color: #056dae;
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
    left: 50%;
    top: 50%;
}
  
@keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 
        2em -2em 0 0em, 3em 0 0 -1em, 
        2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 -1em, -3em 0 0 -1em, 
        -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
        3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 -1em, -3em 0 0 -1em, 
        -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 
        2em -2em 0 0, 3em 0 0 0.2em, 
        2em 2em 0 0, 0 3em 0 -1em, 
        -2em 2em 0 -1em, -3em 0 0 -1em, 
        -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
        -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
        -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
        -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
        3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

.panel-collapse {
    display: flex;
    margin-top: 12px;
    /* height: 0; */
    /* overflow: hidden; */
    /* background: rgb(187, 187, 187); */
    background: #F3F3F3;
    border-radius: 4px;
    position: relative;
    height: 0;
}

.panel-button {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 18px;
    height: 18px;
    background: #EBEBEB;
    border: 1px solid #BBB;
    cursor: pointer;
    border-radius: 5px;
}
.panel-button:hover {
    background: #AAA;
}
.panel-item {
    padding: 18px;
    display: flex;
    flex-direction: column;
    width: 50%;
}
.panel-item-title {
    font-weight: 600;
    font-family: "Inter Semibold";
    padding-bottom: 8px;
    border-bottom: 2px solid rgb(51, 51, 51);
    margin-bottom: 8px;
    color: #201e1d;
}

.panel-action {
    width: 100%;
}

.panel-select {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.body {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #999;
}

@media (max-width: 1280px) {
    .container {
        width: auto;
        margin: 40px 0 0 0;
        padding: 0 10px;
    }
    .header {
        /* border: 1px solid red; */
    }
}

@media (max-width: 767px) {
    .container {
        /* padding: 0 16px; */
    }
    .header {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .input-container {
        width: 100%;
        margin: 0 0 24px 0;
    }
    .action-compare {
        padding: 0 50px;
        margin: 0;
    }

    .panel-collapse {
        flex-direction: column;
    }
    .panel-item {
        width: auto;
        padding: 18px 18px 0 18px
    }
    .panel-item-benchmarks {
        padding:0 18px 18px 18px;
    }
}