.container {
    width: 1050px;
    margin: 40px auto 0 auto;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: column;
}
.header > h1 {
    font-size: 30px;
    line-height: 36px;
    margin: 0;
}

.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    margin-top: 18px;
    border: 1px solid #848B8F;
    border-right: none;
    border-left: none;
}
.action-item {
    display: flex;
    align-items: center;
}
.action-item-hide {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 1);
    cursor: pointer;
    border-radius: 6px;
    margin-right: 24px;
}
.action-item-hide:hover {
    background: rgba(0, 0, 0, 0.8);
}
.action-item-comparison {
    height: 48px;
    display: flex;
    align-items: center;
    background: #056dae;
    cursor: pointer;
    border-radius: 24px;
    padding: 0 50px 0 24px;
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    margin-right: 24px;
}
.action-item-comparison:hover {
    background: #05589d;
}
.action-item-comparison > img {
    margin-right: 24px;
}

.action-item-pdf {
    height: 48px;
    display: flex;
    align-items: center;
    background: rgba(222,174,26, 1);
    cursor: pointer;
    border-radius: 24px;
    padding: 0 36px 0 24px;
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
}
.action-item-pdf:hover {
    background: rgba(222,174,26, 0.8);
}
.action-item-pdf > img {
    margin-right: 24px;
}

.body {
    display: flex;
    margin-top: 24px;
}

.body-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.body-info-chart {
    margin-bottom: 40px;
    display: flex;
}

.body-info-list {
    display: flex;
    justify-content:  space-between;
    margin-top: 24px;
}

.list-container {
    width: calc(50% - 12px);
    flex-shrink: 0;
    margin-right: 24px;
}
.list-top {
    display: flex;
    flex-direction: column;
}
.list-top-tips {
    display: flex;
    flex-direction: row-reverse;
    font-weight: 500;
    color: #056dae;
    margin-bottom: 12px;
}

.top-header {
    display: flex;
    align-items: center;
}
.top-header-info {
    display: flex;
    flex-direction: column;
}
.top-header-info-title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
}
.top-header-info-subtitle {
    font-size: 14px;
    line-height: 16px;
    margin: 15px 0;
}
.top-header-info-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #056dae;
}
.top-header-indicator {
    width: auto;
    flex: 1;
    padding-left: 12px;
    margin-top: 40px;
}
.top-body {
    display: flex;
    flex-direction: column;
}

.list-item {
    background: #F3F2F2;
    padding: 8px 10px;
    margin-bottom: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-bottom {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.trailing {
    width: calc(50% - 12px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1280px) {
    .container {
        width: auto;
        margin: 40px 0 0 0;
        padding: 0 10px;
    }
    .body-info-chart {
        display: block;
    }
    .body-info-list {
        flex-direction: column;
    }
    .list-container {
        width: auto;
        margin-right: 0;
    }
    .list-bottom {
        margin-top: 24px;
    }
    .trailing {
        width: auto;
        margin-top: 24px;
    }
}

@media (max-width: 767px) {
    .container {
        padding: 0 16px;
    }
    .action {
        flex-direction: column-reverse;
        padding: 18px 0;
    }
    .action-item-holdings {
        display: none;
    }
    .action-item-button {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        gap: 12px;
    }
    .action-item-comparison {
        padding: 0;
        margin: 0;
        justify-content: center;
        align-content: center;
        font-size: 12px;
        height: 40px;
    }
    .action-item-comparison > img {
        margin-right: 6px;
    }
    .action-item-pdf {
        padding: 0;
        justify-content: center;
        align-content: center;
        font-size: 12px;
        height: 40px;
    }
    .action-item-pdf > img {
        margin-right: 6px;
    }

    .body {
        flex-direction: column;
    }
}