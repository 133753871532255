.container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
}
.header-action {
    display: flex;
    align-items: center;
}
.header-action-button {
    background: #deae1a;
    border-radius: 6px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    font-weight: 700;
    margin-left: 12px;
    cursor: pointer;
}
.header-action-button:hover {
    opacity: 0.8;
}

.tab-container {
    margin-top: 40px;
    overflow: auto;
}

.table {
    width: 100%;
    border-spacing: 0 10px;
}
.table > thead {
    display: table-header-group;; 
    justify-content: space-between;
    min-width: 100%;
}
.thead-th {
    font-weight: 500;
    text-align: left;
    font-size: 14px;
    padding-right: 14px;
    color: #056dae;
    position: relative;
    padding-bottom: 12px;
}
.thead-th:first-child { 
    padding-left: 16px;
}

.tbody-tr {
    font-size: 14px;
    line-height: 16px;
    background: #F3F2F2;
}

.tbody-td {
    padding: 8px 14px 8px 0;
}
.tbody-td:first-child {
    padding-left: 16px;
}
.tbody-td-title {
    display: none;
}

.table-sort {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    margin: 0 auto 0 0;
    border-top: 1px solid #000000;
}
.table-sort-down {
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: #000000 transparent transparent transparent;
}
.table-sort-up {
    position: absolute;
    top: -16px;
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: transparent transparent #000000 transparent;
}

@media (max-width: 1280px) {
    .container-full {
        margin-left: -335px;
    }
}

@media (max-width: 767px) {
    .container-full {
        margin-left: 0;
    }
    .header {
        flex-direction: column;
        align-items: flex-start;
    }
    .header-action {
        width: 100%;
        justify-content: space-between;
        margin-top: 12px;
    }
    .header-action-button {
        padding: 10px 10px;
        margin-left: 0;
        font-size: 12px;
    }
    .tab-container {
        margin-top: 0;
        overflow: hidden;
        padding-bottom: 10px;
    }

    .thead-th {
        display: none;
    }
    .tbody-tr {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        background: #fff;
        border-radius: 10px;
        padding: 8px 20px 20px 20px;
        box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
        border: 1px solid #D9D9D9;
        width: auto;
        /* display: none; */
    }
    .tbody-tr:first-child {
        margin-top: 0;
    }
    .tbody-td {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
    }
    .tbody-td:first-child {
        padding-left: 0;
    }
    .tbody-td-title {
        display: block;
        white-space:nowrap;
        margin-right: 8px;
        font-size: 14px;
        color: rgba(40, 48, 63, 0.8);
        font-weight: 500;
    }
    .tbody-td-value {
        text-align: right;
        color: rgba(40, 48, 63, 0.6);
    }
}