.container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    min-height: 400px;
    position: relative;
}
.chart {
    flex: 1;
    margin-top: 12px;
    padding-left: 0;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
}
.header-action {
    display: flex;
    align-items: center;
}
.header-action-button {
    background: #deae1a;
    border-radius: 6px;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    font-weight: 700;
    margin-left: 12px;
    cursor: pointer;
}
.header-action-button:hover {
    opacity: 0.8;
}
.header-action-button-active {
    background: #056dae;
    border-radius: 6px;
    padding: 10px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    font-weight: 700;
    margin-left: 12px;
}
/* .header-action-button-active:hover {
    background: #05589d;
} */


.tips {
    border-top: 2px solid #C8C7C7;;
    position: absolute;
    top: 92%;
    right: 9%;
    left: 9%;
    display: flex;
    align-items: center;
    padding-top: 12px;
}

.tips-item {
    margin-right: 35px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}
.tips-item-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    color: #056dae;
    margin-left: 12px;
}

@media (max-width: 1280px) {
    .container {
        margin-left: 0
    }
    .container-full {
        margin-left: -335px;
    }
}

@media (max-width: 767px) {
    .container {
        margin-top: 24px;
    }
    .container-full {
        margin-left: 0;
    }
    .header {
        flex-direction: column;
        align-items: flex-start;
    }
    .header-action {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 12px;
        margin-top: 12px;
    }
    .header-action-button {
        padding: 10px 0;
        margin-left: 0;
    }
    .tips {
        left: 0;
        right: 0;
        justify-content: space-between;
    }
    .tips-item {
        margin-right: 0;
    }
}