.container {
    display: grid;
    gap: 24px;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    height: 100%;;
}

.item {
    font-size: 14px;
    line-height: 16px;
    color: #28303F;
    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 10px 0 10px 10px;
}

.header {
    display: flex;
    align-items: center;
    min-height: 32px;
}
.header > img {
    margin-right: 12px;
    width: 20px;
    height: 20px;
}

.body {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0px;
}

.body-item {
    font-family: "Morningstar Symbols";
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.body-item > img {
    margin-right: 10px;
}
.checkbox-default {
    width: 18px;
    height: 18px;
    margin: 0 12px 0 0;
    cursor: pointer;
}
.checkbox {
    width: 18px;
    height: 18px;
    margin: 0 12px 0 0;
    cursor: pointer;
    border-radius: 2px;
    background: #fff;
    -webkit-appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.7);
}
.checkbox:checked {
    background-color: #056dae;
    border: 1px solid transparent;
    position: relative;
}
.checkbox:checked::before {
    content: " ";
    box-shadow: none;
    opacity: 1;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    border: 2.5px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    top: 3px;
    left: 2px;
}

@media (max-width: 1280px) {
    .header > img {
        margin-right: 6px;
    }
    .body-item > img {
        margin-right: 4px;
    }
    .checkbox {
        margin: 0 6px 0 0;
    }
}
@media (max-width: 767px) {
    .item {
        font-size: 12px;
    }
}